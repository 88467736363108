var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "portfolio-module"
  }, [_c('sygni-tabs', {
    attrs: {
      "options": _vm.pageOptions,
      "legacyStyling": false,
      "disableSlots": true,
      "defaultOption": _vm.selectedOption
    },
    on: {
      "click": _vm.changePage
    },
    scopedSlots: _vm._u([{
      key: "beforeContent",
      fn: function fn() {
        return [_c('GenproxPageHeading', {
          attrs: {
            "title": _vm.pageTitle,
            "breadcrumbsTitle": _vm.pageTitle
          }
        }), _c('portfolio-filters', {
          on: {
            "filtersChange": _vm.applyFiltersEvent
          }
        }), _c('div', {
          staticClass: "portfolio-module__container"
        }, [_c('div', {
          staticClass: "container-fluid"
        }, [_c('portfolio-table', {
          ref: "portfolioTable",
          attrs: {
            "show-page-number-options": true,
            "tableData": _vm.portfolioTable,
            "tablePerPageMutation": "portfolio/setPortfolioTablePerPage",
            "setTableBusyMutation": "portfolio/setPortfolioTableBusy",
            "busy": _vm.isBusy,
            "perPage": _vm.perPageOption,
            "getItemsAction": "portfolio/getPortfolioTableItems"
          },
          on: {
            "changePerPageOption": _vm.changePerPageOption,
            "openDetailsModal": _vm.openDetailsModal
          }
        })], 1)]), _c('portfolio-modal', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showPortfolioModal,
            expression: "showPortfolioModal"
          }],
          attrs: {
            "isLoading": _vm.isPortfolioModalLoading,
            "showNavigation": _vm.selectedDetails.length > 1,
            "transactionId": _vm.transactionId
          },
          on: {
            "close": _vm.closePortfolioModal,
            "loaded": _vm.detailsModalLoaded,
            "prev": function prev($event) {
              return _vm.setIndexes(_vm.prevIndex);
            },
            "next": function next($event) {
              return _vm.setIndexes(_vm.nextIndex);
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }