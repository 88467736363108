var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-inner-table",
    attrs: {
      "innertable": "true",
      "fields": _vm.innerTableFields
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table__company"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$emit('openDetailsModal', rowData.item, _vm.items);
            }
          }
        }, [_vm._v(_vm._s(rowData.item.code))])])];
      }
    }, {
      key: "cell(seriesOfSharesName)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('span', [_vm._v(_vm._s((_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.seriesOfSharesName ? rowData.item.seriesOfSharesName : 'N/A'))])];
      }
    }, {
      key: "cell(instrumentCount)",
      fn: function fn(rowData) {
        var _rowData$item2;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("numberFormat")((_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.instrumentCount, 0)))])])];
      }
    }, {
      key: "cell(nominalValue)",
      fn: function fn(rowData) {
        var _rowData$item3, _rowData$item3$nomina;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item3 = rowData.item) === null || _rowData$item3 === void 0 ? void 0 : (_rowData$item3$nomina = _rowData$item3.nominalValue) === null || _rowData$item3$nomina === void 0 ? void 0 : _rowData$item3$nomina.value, 2)) + " ")])])];
      }
    }, {
      key: "cell(capitalization)",
      fn: function fn(rowData) {
        var _rowData$item4, _rowData$item4$capita, _rowData$item5, _rowData$item5$capita;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item4 = rowData.item) === null || _rowData$item4 === void 0 ? void 0 : (_rowData$item4$capita = _rowData$item4.capitalization) === null || _rowData$item4$capita === void 0 ? void 0 : _rowData$item4$capita.value, 2)) + " " + _vm._s((_rowData$item5 = rowData.item) === null || _rowData$item5 === void 0 ? void 0 : (_rowData$item5$capita = _rowData$item5.capitalization) === null || _rowData$item5$capita === void 0 ? void 0 : _rowData$item5$capita.currency))])])];
      }
    }, {
      key: "cell(investment)",
      fn: function fn(rowData) {
        var _rowData$item6, _rowData$item6$invest, _rowData$item7, _rowData$item7$invest;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item6 = rowData.item) === null || _rowData$item6 === void 0 ? void 0 : (_rowData$item6$invest = _rowData$item6.investment) === null || _rowData$item6$invest === void 0 ? void 0 : _rowData$item6$invest.value, 2)) + " " + _vm._s((_rowData$item7 = rowData.item) === null || _rowData$item7 === void 0 ? void 0 : (_rowData$item7$invest = _rowData$item7.investment) === null || _rowData$item7$invest === void 0 ? void 0 : _rowData$item7$invest.currency))])])];
      }
    }, {
      key: "cell(porfolioValuation)",
      fn: function fn(rowData) {
        var _rowData$item8, _rowData$item8$porfol, _rowData$item9, _rowData$item9$porfol;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item8 = rowData.item) === null || _rowData$item8 === void 0 ? void 0 : (_rowData$item8$porfol = _rowData$item8.porfolioValuation) === null || _rowData$item8$porfol === void 0 ? void 0 : _rowData$item8$porfol.value, 2)) + " " + _vm._s((_rowData$item9 = rowData.item) === null || _rowData$item9 === void 0 ? void 0 : (_rowData$item9$porfol = _rowData$item9.porfolioValuation) === null || _rowData$item9$porfol === void 0 ? void 0 : _rowData$item9$porfol.currency))])])];
      }
    }, {
      key: "cell(totalValue)",
      fn: function fn(rowData) {
        var _rowData$item10, _rowData$item10$total;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item10 = rowData.item) === null || _rowData$item10 === void 0 ? void 0 : (_rowData$item10$total = _rowData$item10.totalValue) === null || _rowData$item10$total === void 0 ? void 0 : _rowData$item10$total.value, 2)) + " ")])])];
      }
    }, {
      key: "cell(invested)",
      fn: function fn(rowData) {
        var _rowData$item11, _rowData$item12, _rowData$item12$inves, _rowData$item13, _rowData$item13$inves;

        return [(_rowData$item11 = rowData.item) !== null && _rowData$item11 !== void 0 && _rowData$item11.invested ? _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item12 = rowData.item) === null || _rowData$item12 === void 0 ? void 0 : (_rowData$item12$inves = _rowData$item12.invested) === null || _rowData$item12$inves === void 0 ? void 0 : _rowData$item12$inves.value, 2)) + " " + _vm._s((_rowData$item13 = rowData.item) === null || _rowData$item13 === void 0 ? void 0 : (_rowData$item13$inves = _rowData$item13.invested) === null || _rowData$item13$inves === void 0 ? void 0 : _rowData$item13$inves.currency) + " ")]) : _vm._e()];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }