var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "portfolio-table"
  }, [_c('div', {
    staticClass: "portfolio-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "portfolioTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(investedCompanyName)",
      fn: function fn(rowData) {
        var _rowData$item, _rowData$item2;

        return [_c('user-presentation', {
          staticClass: "table__user-presentation",
          attrs: {
            "name": (_rowData$item = rowData.item) === null || _rowData$item === void 0 ? void 0 : _rowData$item.investedCompanyName,
            "image": (_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.investedCompanyLogo
          }
        })];
      }
    }, {
      key: "cell(investedFundName)",
      fn: function fn(rowData) {
        var _rowData$item3, _rowData$item4;

        return [_c('user-presentation', {
          staticClass: "table__user-presentation",
          attrs: {
            "name": (_rowData$item3 = rowData.item) === null || _rowData$item3 === void 0 ? void 0 : _rowData$item3.investedFundName,
            "image": (_rowData$item4 = rowData.item) === null || _rowData$item4 === void 0 ? void 0 : _rowData$item4.investedFundLogo
          }
        })];
      }
    }, {
      key: "cell(industry)",
      fn: function fn(rowData) {
        var _rowData$item5;

        return [_c('span', [_vm._v(_vm._s(_vm.getIndustryLabel((_rowData$item5 = rowData.item) === null || _rowData$item5 === void 0 ? void 0 : _rowData$item5.industry)))])];
      }
    }, {
      key: "cell(stage)",
      fn: function fn(rowData) {
        var _rowData$item6;

        return [_c('span', [_vm._v(_vm._s(_vm.getStageLabel((_rowData$item6 = rowData.item) === null || _rowData$item6 === void 0 ? void 0 : _rowData$item6.stage)))])];
      }
    }, {
      key: "cell(seriesOfSharesName)",
      fn: function fn(rowData) {
        var _rowData$item7;

        return [_c('span', [_vm._v(_vm._s(rowData.item.seriesOfSharesName ? (_rowData$item7 = rowData.item) === null || _rowData$item7 === void 0 ? void 0 : _rowData$item7.seriesOfSharesName : 'N/A'))])];
      }
    }, {
      key: "cell(instrumentClass)",
      fn: function fn(rowData) {
        var _rowData$item8;

        return [_c('span', [_vm._v(_vm._s(_vm.getInstrumentClassLabel((_rowData$item8 = rowData.item) === null || _rowData$item8 === void 0 ? void 0 : _rowData$item8.instrumentClass)))])];
      }
    }, {
      key: "cell(instrumentCount)",
      fn: function fn(rowData) {
        var _rowData$item9;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("numberFormat")((_rowData$item9 = rowData.item) === null || _rowData$item9 === void 0 ? void 0 : _rowData$item9.instrumentCount, 0)))])])];
      }
    }, {
      key: "cell(dealCount)",
      fn: function fn(rowData) {
        var _rowData$item10;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("numberFormat")((_rowData$item10 = rowData.item) === null || _rowData$item10 === void 0 ? void 0 : _rowData$item10.dealCount, 0)))])])];
      }
    }, {
      key: "cell(instrumentType)",
      fn: function fn(rowData) {
        var _rowData$item11;

        return [_c('span', [_vm._v(_vm._s(_vm.getInstrumentTypeLabel((_rowData$item11 = rowData.item) === null || _rowData$item11 === void 0 ? void 0 : _rowData$item11.instrumentType)))])];
      }
    }, {
      key: "cell(nominalValue)",
      fn: function fn(rowData) {
        var _rowData$item12, _rowData$item12$nomin;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item12 = rowData.item) === null || _rowData$item12 === void 0 ? void 0 : (_rowData$item12$nomin = _rowData$item12.nominalValue) === null || _rowData$item12$nomin === void 0 ? void 0 : _rowData$item12$nomin.value, 2)) + " ")])])];
      }
    }, {
      key: "cell(total)",
      fn: function fn(rowData) {
        var _rowData$item13, _rowData$item13$total;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item13 = rowData.item) === null || _rowData$item13 === void 0 ? void 0 : (_rowData$item13$total = _rowData$item13.total) === null || _rowData$item13$total === void 0 ? void 0 : _rowData$item13$total.value, 2)) + " ")])])];
      }
    }, {
      key: "cell(investedFundCapitalisation)",
      fn: function fn(rowData) {
        var _rowData$item14, _rowData$item14$inves, _rowData$item15, _rowData$item15$inves;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item14 = rowData.item) === null || _rowData$item14 === void 0 ? void 0 : (_rowData$item14$inves = _rowData$item14.investedFundCapitalisation) === null || _rowData$item14$inves === void 0 ? void 0 : _rowData$item14$inves.value, 2)) + " " + _vm._s((_rowData$item15 = rowData.item) === null || _rowData$item15 === void 0 ? void 0 : (_rowData$item15$inves = _rowData$item15.investedFundCapitalisation) === null || _rowData$item15$inves === void 0 ? void 0 : _rowData$item15$inves.currency))])])];
      }
    }, {
      key: "cell(portfolioValuation)",
      fn: function fn(rowData) {
        var _rowData$item16, _rowData$item16$portf, _rowData$item17, _rowData$item17$portf;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item16 = rowData.item) === null || _rowData$item16 === void 0 ? void 0 : (_rowData$item16$portf = _rowData$item16.portfolioValuation) === null || _rowData$item16$portf === void 0 ? void 0 : _rowData$item16$portf.value, 2)) + " " + _vm._s((_rowData$item17 = rowData.item) === null || _rowData$item17 === void 0 ? void 0 : (_rowData$item17$portf = _rowData$item17.portfolioValuation) === null || _rowData$item17$portf === void 0 ? void 0 : _rowData$item17$portf.currency))])])];
      }
    }, {
      key: "cell(invested)",
      fn: function fn(rowData) {
        var _rowData$item18, _rowData$item18$inves, _rowData$item19, _rowData$item19$inves;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item18 = rowData.item) === null || _rowData$item18 === void 0 ? void 0 : (_rowData$item18$inves = _rowData$item18.invested) === null || _rowData$item18$inves === void 0 ? void 0 : _rowData$item18$inves.value, 2)) + " " + _vm._s((_rowData$item19 = rowData.item) === null || _rowData$item19 === void 0 ? void 0 : (_rowData$item19$inves = _rowData$item19.invested) === null || _rowData$item19$inves === void 0 ? void 0 : _rowData$item19$inves.currency))])])];
      }
    }, {
      key: "cell(investment)",
      fn: function fn(rowData) {
        var _rowData$item20, _rowData$item21, _rowData$item21$inves, _rowData$item22, _rowData$item22$inves, _rowData$item23, _rowData$item24;

        return [_c('div', {
          class: ['table__arrow', 'd-flex', 'align-items-center', 'pl-4 pr-4', ((_rowData$item20 = rowData.item) === null || _rowData$item20 === void 0 ? void 0 : _rowData$item20.portfolioValuation) == undefined ? 'justify-content-between' : 'justify-content-end']
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item21 = rowData.item) === null || _rowData$item21 === void 0 ? void 0 : (_rowData$item21$inves = _rowData$item21.investment) === null || _rowData$item21$inves === void 0 ? void 0 : _rowData$item21$inves.value, 2)) + " " + _vm._s((_rowData$item22 = rowData.item) === null || _rowData$item22 === void 0 ? void 0 : (_rowData$item22$inves = _rowData$item22.investment) === null || _rowData$item22$inves === void 0 ? void 0 : _rowData$item22$inves.currency) + " ")]), ((_rowData$item23 = rowData.item) === null || _rowData$item23 === void 0 ? void 0 : _rowData$item23.portfolioValuation) == undefined ? _c('div', {
          staticClass: "arrow",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_c('sygni-arrow-button', {
          attrs: {
            "open": (_rowData$item24 = rowData.item) === null || _rowData$item24 === void 0 ? void 0 : _rowData$item24._showDetails,
            "rotate-on-click": true
          }
        })], 1) : _vm._e()])];
      }
    }, {
      key: "cell(reportStatus)",
      fn: function fn(rowData) {
        var _rowData$item$reportS, _rowData$item25;

        return [_c('div', {
          staticClass: "table__arrow d-flex justify-content-end align-items-center pl-4 pr-4"
        }, [_c('span', [_vm._v(_vm._s((_rowData$item$reportS = rowData.item.reportStatus) === null || _rowData$item$reportS === void 0 ? void 0 : _rowData$item$reportS.toUpperCase()))]), rowData.item.reportStatus != undefined ? _c('div', {
          staticClass: "arrow",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_c('sygni-arrow-button', {
          attrs: {
            "open": (_rowData$item25 = rowData.item) === null || _rowData$item25 === void 0 ? void 0 : _rowData$item25._showDetails,
            "rotate-on-click": true
          }
        })], 1) : _vm._e()])];
      }
    }, {
      key: "row-details",
      fn: function fn(rowData) {
        var _rowData$item26;

        return [_c('portfolio-inner-table', {
          attrs: {
            "items": (_rowData$item26 = rowData.item) === null || _rowData$item26 === void 0 ? void 0 : _rowData$item26.details
          },
          on: {
            "openDetailsModal": _vm.openDetailsModal
          }
        })];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }