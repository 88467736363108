

















import Vue from 'vue'
import Component from 'vue-class-component'
import PortfolioFilters from '../components/PortfolioFilters.vue'
import SygniTabs from '@/components/layout/SygniTabs.vue'
import PortfolioTable from '../components/PortfolioTable.vue'
import PortfolioModal from '../components/PortfolioModal.vue'
import {mapState} from "vuex";
import { Watch } from 'vue-property-decorator'
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue'

@Component({
  components: { PortfolioFilters, SygniTabs, PortfolioTable, PortfolioModal, GenproxPageHeading },
  computed: mapState('portfolio', {
    portfolioTable: (state: any) => state.portfolioTable,
    isBusy: (state: any) => state.portfolioTableIsBusy,
    perPage: (state: any) => state.portfolioTable.perPage,
  })
})
export default class PortfolioModule extends Vue {
  filtersQueryString: string = '';
  perPageOption?: number = 10;
  showPortfolioModal: boolean = false;
  isPortfolioModalLoading: boolean = false;
  transactionId: string = '';
  selectedDetails: Array<any> = [];
  activeIndex?: number = null;
  prevIndex: number = 0;
  nextIndex: number = 0;

  pageOptions: any = [
    {
      label: 'Investment by fund',
      key: 'investor-portfolio-by-fund'
    },
    {
      label: 'Investment by company',
      key: 'investor-portfolio-by-company'
    },
  ];

  openDetailsModal(id: string, items: Array<any>) {
    const currentIndex = items.findIndex(el => el.id == id);
    this.selectedDetails = items;
    this.setIndexes(currentIndex);
    this.showPortfolioModal = true;
  }

  detailsModalLoaded() {
    this.isPortfolioModalLoading = false;
  }

  closePortfolioModal() {
    this.showPortfolioModal = false;
    this.selectedDetails = [];
    this.activeIndex = null;
    this.transactionId = '';
  }

  changePerPageOption(perPage: number) {
    this.perPageOption = perPage;
  }

  applyFiltersEvent(queryString: string) {
    this.filtersQueryString = queryString;
    (this.$refs.portfolioTable as PortfolioTable).onFiltersChange(queryString);
  }

  setIndexes(index: number) {
    if(this.activeIndex == index) return;

    this.activeIndex = index;
    this.prevIndex = (this.activeIndex == undefined) ? undefined : (Number(this.activeIndex) == 0) ? this.selectedDetails.length - 1 : Number(this.activeIndex) - 1;
    this.nextIndex = (this.activeIndex == undefined) ? undefined : (this.selectedDetails.length <= Number(this.activeIndex) + 1) ? 0 : Number(this.activeIndex) + 1;
    this.isPortfolioModalLoading = true;
  }

  changePage(page: string) {
    if(page != this.selectedOption) {
      this.$router.push({ name: page });
    }
  }

  get pageTitle() {
    return this.selectedOption === 'investor-portfolio-by-company' ? 'Investment by company' : 'Investment by fund'
  }

  get perPageValue() {
    return this.perPageOption;
  }

  get accessModules() {
    return this.$store.getters['genprox/getAccessModules'];
  }

  get selectedOption() {
    if(this.$route.name == 'investor-portfolio-by-company' || this.$route.name == 'investor-portfolio-by-fund') return this.$route.name;

    return undefined;
  }

  async beforeMount() {
    if(this.accessModules?.portfolio) {
      await this.$store.dispatch('portfolio/getDictionaries');
    }
  }

  @Watch('activeIndex') onActiveIndexChange() {
    const activeTransaction = this.selectedDetails[this.activeIndex];

    if(activeTransaction) {
      this.transactionId = activeTransaction.id;
    }
  }
}
